import React, { useCallback, useEffect } from 'react';

// Components
import Venue from '../../components/venue';
import Form from '../../components/form';
import Notes from '../../components/notes';
import Loader from '../../components/loader';
import './createReservation.scss';
import { RefreshPage } from '../../components/refreshPage';
import { useParams } from 'react-router-dom';
import { useFormStore } from '../../store';
import Buttons from '../../components/form/buttons';

export default function CreateReservation() {
	const { venueId } = useParams();
	const fetchVenueAvailability = useFormStore(
		(state) => state.fetchVenueAvailability,
	);
	const setVenueId = useFormStore((state) => state.setVenueId);
	const date = useFormStore((state) => state.date);
	const time = useFormStore((state) => state.time);
	const partySize = useFormStore((state) => state.partySize);
	const loading = useFormStore((state) => state.loading);
	setVenueId(venueId);

	const handleGetAvailability = useCallback(() => {
		fetchVenueAvailability(true);
	}, [fetchVenueAvailability]);

	useEffect(() => {
		handleGetAvailability();
	}, [date, time, partySize, handleGetAvailability]);

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<div className="reserveContainer">
					<div className="reserveHead">
						<RefreshPage
							className="reserveClose"
							buttonText="Close"
							buttonStyles={false}
							onClick={() => window.location.reload()}
						/>
						<p className="reserveTitle">Reserve</p>
						<p className="reserveClose"></p>
					</div>
					<Venue />
					<Form />
					<Notes />
					<Buttons />
				</div>
			)}
		</>
	);
}
