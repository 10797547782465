import { createContext, useState } from 'react';
import useStorage from '../hooks/useStorage';
const AuthContext = createContext();

export function AuthContextProvider(props) {
	const { children } = props;
	const [accessToken, setAccessToken] = useStorage('CookieAuth', '');
	const [venueId] = useStorage('VenueId', '');
	const [user, setUser] = useState(null);

	const contextValue = {
		accessToken,
		setAccessToken,
		user,
		setUser,
	};

	if (process.env.NODE_ENV === 'development') {
		console.log('AuthContext:', contextValue);
	}

	return (
		<AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
	);
}

export { AuthContext };
