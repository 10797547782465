import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import TextField from '@mui/material/TextField';
import { MobileDatePicker } from '@mui/x-date-pickers';

import DialogActions from '@mui/material/DialogActions';

import { WrapperVariantContext } from '@mui/x-date-pickers/internals';
import moment from 'moment';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InputAdornment from '@mui/material/InputAdornment';
import { browserIsSafari } from '../../../helpers/isSafari';

import './timeBox.scss';

import { blackColor, secondaryBlackColor, darkGreyColor, whiteColor } from '../../../helpers/colorConstants';

const fontType = 'sofiapro-light';
const isSafari = browserIsSafari();

const theme = createTheme({
	components: {
		MuiModal: {
			styleOverrides: {
				root: {
					fontFamily: fontType,
					'&.MuiDialog-root .MuiDialog-paper': {
						minWidth: '100%',
						minHeight: '100vh',
						top: '60%',
					},
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				root: {
					margin: '0px',
					padding: '0px',
					border: '0px',
					backgroundColor: blackColor,
					fontFamily: fontType,
				},
				paper: {
					backgroundColor: blackColor,
					fontFamily: fontType,
				},
				container: {
					backgroundColor: blackColor,
					background: `linear-gradient(180deg, ${blackColor} 0%, #0A0703 100%)`,
					fontFamily: fontType,
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					'&:first-of-type': {
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						fontFamily: fontType,
						backgroundColor: blackColor,
					},
				},
			},
		},
		//--------title----------------------
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					color: whiteColor,
					fontFamily: fontType,
					width: '1.5em',
					height: '1.5em',
					'&.MuiPickersCalendarHeader-switchViewIcon': {
						width: '1em',
						height: '1em',					
						path: {
							d: 'path("M 8.59 16.59 L 13.17 12 L 8.59 7.41 L 10 6 l 6 6 l -6 6 l -1.41 -1.41 Z")',							
						},
					}
				},
			},
		},
		MuiPickersArrowSwitcher: {
			styleOverrides: {
				spacer: {
					width: '0px',
				},
			},
		},
		MuiPickersCalendarHeader: {
			styleOverrides: {
				label: {
					fontSize: '17px',
					lineHeight: '22px',
					fontFamily: fontType,
					marginRight: '0px',

				},
				root: {
					paddingLeft: '10px',
					paddingRight: '0px',					
				}
			},
		},
		//--------day of week-----------------
		MuiTypography: {
			styleOverrides: {
				root: {
					backgroundColor: blackColor,
					fontSize: '15px',
					lineHeight: '18px',
					fontFamily: fontType,
				},
			},
		},
		MuiPickersDay: {
			styleOverrides: {
				root: {
					color: whiteColor,
					backgroundColor: blackColor,
					fontSize: '20px',
					lineHeight: '23px',
					fontFamily: fontType,
					'&.Mui-selected': {
						backgroundColor: whiteColor,
						fontFamily: fontType,
						color: secondaryBlackColor,
						fontSize: '20px',				
						fontWeight: '600',
						display: 'flex',
						alignItems: 'center',
						textAlign: 'center',
						justifyContent: 'center',
						height: '34px',
						width: '34px',				
						letterSpacing: '0',
						lineHeight: '1',
						paddingTop: isSafari ? '3px' : ''					
					},
					'&.Mui-selected:hover': {
						backgroundColor: whiteColor,
						fontFamily: fontType,
					},
					'&.Mui-selected:focus': {
						backgroundColor: whiteColor,
						fontFamily: fontType,
					},
					'&:not(.Mui-selected)':{
						border: 'none'
					}
				},
			},
		},
		MuiDayPicker: {
			styleOverrides: {
				weekDayLabel: {
					backgroundColor: blackColor,
					color: darkGreyColor,
					fontFamily: fontType,
				},
				header: {
					justifyContent: 'space-evenly',
				},
				weekContainer: {
					justifyContent: 'space-evenly',
					paddingTop: '5px'
				}
			},
		},
		MuiTouchRipple: {
			styleOverrides: {
				root: {
					color: blackColor,
					fontFamily: fontType,
				},
			},
		},
		//-------calendar----------------------
		PrivatePickersYear: {
			styleOverrides: {
				root: {
					
				},
				button: {
					backgroundColor: blackColor,
					fontFamily: fontType,
					fontSize: '20px',
					'&.Mui-selected': {
						backgroundColor: whiteColor,
						fontFamily: fontType,
						color: blackColor,
						fontWeight: 600,
						display: 'flex',
						alignItems: 'center',
						textAlign: 'center',
						justifyContent: 'center',
						paddingBottom: '0px',
						height: '34px',
						letterSpacing: '0',
						lineHeight: '1',
						paddingTop: isSafari ? '3px' : ''
					},
					'&.Mui-selected:hover': {
						backgroundColor: whiteColor,
						fontFamily: fontType,
					},
					'&.Mui-selected:focus': {
						backgroundColor: whiteColor,
						fontFamily: fontType,
					},
					'&.Mui-disabled': {
						opacity: '0.2',
						color: whiteColor,
					},
				},
			},
		},
		MuiCalendarPicker: {
			styleOverrides: {
				root: {
				},	
			},
		},	
		//-------body--------------------------
		MuiPickersToolbar: {
			styleOverrides: {
				root: {
					display: 'none',
				},
			},
		},
		MuiCalendarOrClockPicker: {
			styleOverrides: {
				root: {
					color: whiteColor,
					backgroundColor: blackColor,
					boxShadow: `0px 10px 60px rgba(0, 0, 0, 0.1)`,
					margin: '0px',
					padding: '0px',
					fontFamily: fontType,
					position: 'fixed',
					top: '50%',
					transform: 'translateY(-50%)',
					width: '95%',
					height: '50%',
					borderRadius: '10px',
					'@media only screen and (max-height: 668px) and (min-height: 500px)': {
						width: '357px',
						height: '370px',
					},
				},
			},
		},
		'css-epd502': {
			styleOverrides: {
				root: {
					backgroundColor: 'blue',
					with: '95vw',
				}
			}
		},
		css: {
			styleOverrides: {
				epd502: {
					with: '95vw',
					backgroundColor: 'blue',
				}
			}
		},

		//-------Time Box----------------------
		MuiPaper: {
			styleOverrides: {
				root: {
					'&.MuiTimeBox': {
						backgroundColor: whiteColor,
						color: whiteColor,
						fontFamily: fontType,
					},
				},
			},
		},
		//-------buttons------------------------
		MuiDialogActions: {
			styleOverrides: {
				root: {
					justifyContent: 'center',
					margin: '0px',
					padding: '0px',
					border: '0px',
					backgroundColor: blackColor,
					minWidth: '100%',
					fontFamily: fontType,
					zIndex: '1',
					position: 'absolute',
					top: '32.5%',
					'@media only screen and (max-height: 668px) and (min-height: 500px)': {
						top: '30.5%',
					},
					'&>:not(:first-of-type)':{
						marginLeft: '0px'
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					backgroundColor: whiteColor,
					minWidth: '42%',
					borderRadius: '0px',
					justifyContent: 'center',
					textTransform: 'capitalize',
					fontSize: '17px',
					padding: '0px',
					fontFamily: fontType,
				},
				text: {
					color: blackColor,
					fontFamily: fontType,
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					display: 'flex',
					textAlign: 'center',
					justifyContent: 'center',
					alignItems: 'center',
					fontFamily: fontType,
				},
			},
		},
		//-------Cancel and Set button------------------------
		MuiButtonBase: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.children === 'Cancel' && {
						'&.MuiMenuItem-root': {
							backgroundColor: blackColor,
							color: whiteColor,
							borderColor: whiteColor,
							borderStyle: 'solid',
							borderWidth: '1px',
							minWidth: '100%',
							fontFamily: fontType,
							fontWeight: '700',
							'&:hover': {
								backgroundColor: blackColor,
								fontFamily: fontType,
								fontWeight: '700',
							},
						},
					}),
					...(ownerState.children === 'Set' && {
						'&.MuiMenuItem-root': {
							color: blackColor,
							minWidth: '100%',
							backgroundColor: whiteColor,
							borderStyle: 'solid',
							marginRigth: '2%',
							fontFamily: fontType,
							fontWeight: '700',
							'&:hover': {
								backgroundColor: whiteColor,
								fontFamily: fontType,
								fontWeight: '700',
							},
						},
					}),
					'&.MuiPickersDay-root.Mui-disabled': {
						opacity: '0.2',
						color: whiteColor,
					},
					'&.MuiIconButton-root':{
						paddingLeft: '0px',
					}
				}),
			},
		},
	},
});

const CustomTimeBar = (props) => {
	const { time } = props;
	return (
		<div className="MuiTimeBox">
			<div className="MuiTimeMiniBox">
				<div className="MuiTimeTimeBox">Time</div>
				<div className="MuiTimeHourBox">
					{moment(time, 'LT').format('h:mm A')}
				</div>
			</div>
		</div>
	);
};

const CustomActionBar = (props) => {
	const { onAccept, onCancel, actions, time } = props;
	const wrapperVariant = React.useContext(WrapperVariantContext);
	const actionsArray =
		typeof actions === 'function' ? actions(wrapperVariant) : actions;

	if (actionsArray == null || actionsArray.length === 0) {
		return null;
	}

	return (
		<>
			<CustomTimeBar time={time} />
			<DialogActions
				sx={{
					marginBottom: -3,	
				}}>
				<button
					className={'ConfirmingButtonPicker'}
					onClick={() => {
						onAccept();
					}}>
					Set
				</button>
				<button
					className={'CancelButtonPicker'}
					onClick={() => {
						onCancel();
					}}>
					Cancel
				</button>
			</DialogActions>
		</>
	);
};
export default function CustomDatePicker(props) {
	const { label, onChange, name, value, time, onOpen, isDisabled } = props;
	const today = moment().format('YYYY-MM-DD');

	const handleChange = (newValue) => {
		if (moment(newValue).isSame(today) || moment(newValue).isAfter(today)) {
			if (onChange) onChange(moment(newValue).format('YYYY-MM-DD'));
		}
	};
	return (
		<>
			<label>{label}</label>
			<ThemeProvider theme={theme}>
				<MobileDatePicker
					id="date"
					disabled={isDisabled}
					name={name}
					type="date"
					inputFormat="ddd, MMM D"
					dayOfWeekFormatter={(day) => `${day.toUpperCase()}`}
					disablePast={true}
					value={value}
					onOpen={onOpen}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={handleChange}
					components={{
						ActionBar: CustomActionBar,
						OpenPickerIcon: CustomTimeBar,
					}}
					minDate={moment().subtract(9, 'years')}
					maxDate={moment().add(2, 'years')}
					componentsProps={{
						actionBar: {
							actions: ['accept', 'cancel'],
							time: time,
						},
						OpenPickerIcon: {
							time: time,
						},
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							sx={{
								width: '100%',
								border: `solid 2px ${darkGreyColor}`,
								height: '32px',
								'.MuiInputBase-input': {
									color: whiteColor,
									fontSize: '13px',
									lineHeight: '14,95px',
									backgroundColor: secondaryBlackColor,
									padding: '0',
									paddingLeft: '10px',
									height: '100%',
								},
								'.MuiOutlinedInput-root': {
									height: '100%',
								},
								'.MuiOutlinedInput-notchedOutline': {
									border: 'none',
								},
								'.MuiInputBase-adornedEnd': {
									padding: '0px',
								},
								'.MuiOutlinedInput-input.Mui-disabled': {
									WebkitTextFillColor: whiteColor,
								},
								'.MuiOutlinedInput-input': {
									borderRadius: 'none',
									height: '100%',
								},
							}}
							InputProps={{
								endAdornment: !isDisabled && (
									<InputAdornment
										position="end"
										sx={{
											backgroundColor: secondaryBlackColor,
											margin: '0',
											height: '100%',
											border: 'none',
										}}>
										{!onOpen ? (
											<ArrowDropUpIcon
												sx={{
													color: whiteColor,
													paddingRight: '5px',
													border: 'none',
													fontSize: '100%'
												}}
											/>
										) : (
											<ArrowDropDownIcon
												sx={{
													color: whiteColor,
													paddingRight: '5px',
													border: 'none',
													fontSize: '100%'
												}}
											/>
										)}
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
			</ThemeProvider>
		</>
	);
}
