import Reservation from '../../components/reservation';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getReservation } from '../../services';
import background from '../../assets/images/background.png';

import './statusReservation.scss';

export default function StatusReservation() {
	const { reservationId } = useParams();
	const [reservation, setReservation] = useState(null);
	useEffect(() => {
		if (reservationId) {
			const fetchData = async () => {
				let reservation = await getReservation(reservationId);
				setReservation(reservation);
			};
			fetchData();
		}
	}, [reservationId]);

	return (
		<>
			<div
				className="reservationContainer"
				style={{
					backgroundImage: `url(${background})`,
				}}>
				<div className="reservationStatusContainer">
					{reservation && (
						<Reservation
							status={reservation.status}
							venue={reservation.venue}
							city={reservation.city}
							date={reservation.date}
							time={reservation.time}
							guests={reservation.guests}
							table={reservation.table}
						/>
					)}
				</div>
			</div>
		</>
	);
}
