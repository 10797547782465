import React from 'react';
import { useFormStore } from '../../../store';
import CustomDatePicker from '../CustomDatePicker';

export default function Date() {
	const {
		date,
		resetSomeStates,
		setDate,
		time,
		toggleShowPickerGuests,
		toggleShowPickerTime,
	} = useFormStore((state) => state);

	const onChange = (date) => {
		setDate(date);
		resetSomeStates();
	};

	return (
		<>
			<div className="DateTime__DateTimeInputBox">
				<CustomDatePicker
					id="date"
					name={date}
					label="Date"
					onChange={(date) => onChange(date)}
					onOpen={() => {
						toggleShowPickerGuests(false);
						toggleShowPickerTime(false);
					}}
					value={date}
					time={time}
				/>
			</div>
		</>
	);
}
