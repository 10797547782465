import axios from '../client';

const adapterResponse = (data) => {
	if (!data) return;
  return {
		status: data.status,
		venue: data.venue,
		city: data.city,
		date: data.date,
		time: data.time,
		guests: data.guests,
    table: data.table
  };
};

export const getReservation = async (reservationId) => {
	try {
		const reservation = await axios.get(`/api/v1/user/reservation/${reservationId}`)
			.then((res) => adapterResponse(res.data.reservation))
			.catch((err) => err.message);
		return reservation;
	} catch (e) {
		return e;
	}
};
