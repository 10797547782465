import React from 'react';
import AvailableTimes from './availableTimes';
import './index.scss';
import Inputs from './inputs';

export default function Form() {
	return (
		<>
			<form className="reserveForm">
				<Inputs />
				<AvailableTimes />
			</form>
		</>
	);
}
