import { useState, useEffect, createContext } from 'react';
import { debounce } from 'lodash';
import useStorage from '../hooks/useStorage';

const AppContext = createContext();

const AppContextProvider = (props) => {
	const { children } = props;
	const [screenDimensions, setScreenDimensions] = useState(window.innerWidth);
	const [loading, setLoading] = useState(true);
	const [venueId, setVenueId] = useStorage('VenueId', '');
	const [reservationType, setReservationType] = useStorage(
		'ReservationType',
		'',
	);
	const [reservationId, setReservationId] = useStorage('ReservationId', ''); 

	useEffect(() => {
		const handleResize = debounce(() => {
			setScreenDimensions(window.innerWidth);
		}, 100);

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const contextValue = {
		screenDimensions,
		setLoading,
		loading,
		venueId,
		setVenueId,
		reservationType,
		setReservationType,
		reservationId,
		setReservationId,
	};

	if (process.env.NODE_ENV === 'development') {
		console.log('AppContext:', contextValue);
	}

	return (
		<AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
	);
};

export { AppContext, AppContextProvider };
