import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormStore } from '../../../store';

export default function Buttons() {
	const {
		showPickerGuests,
		showPickerTime,
		sendingData,
		time,
		partySize,
		availableTimeSelected,
		fetchCreateReservation,
		reservationCreatedInfo,
		resetSomeStates,
		availability,
		reservationId,
		fetchUpdateReservation,
		reservationUpdatedInfo,
	} = useFormStore((state) => state);
	const navigate = useNavigate();

	const [changeHappened, setChangeHappened] = useState(false);

	const disableContinueButton = useCallback(() => {
		if (!availableTimeSelected) return false;
		const some = availability.some(
			(a) =>
				moment(a.time, 'LT').format('hh:mm a') ===
				moment(availableTimeSelected, 'LT').format('hh:mm a'),
		);
		if (some) {
			return sendingData ? true : changeHappened ? true : false;
		}
		return false;
	}, [availability, availableTimeSelected, changeHappened, sendingData]);

	useEffect(() => {
		setChangeHappened(
			partySize && time && availableTimeSelected && availability.length,
		);
	}, [availability, partySize, time, availableTimeSelected]);

	useEffect(() => {
		if (reservationCreatedInfo.reservationId) {
			resetSomeStates();
			navigate(`/status-reservation/${reservationCreatedInfo.reservationId}`);
		}
	}, [navigate, reservationCreatedInfo, resetSomeStates]);

	const handleCreateReservation = async () => {
		await fetchCreateReservation();
	};

	const cancelAReservation = (e) => {
		e.preventDefault();
		navigate(`/cancel-reservation/${reservationId}`);
	};

	const handleUpdateReservation = async () => {
		await fetchUpdateReservation();
	};

	useEffect(() => {
		if (reservationUpdatedInfo.updated) {
			navigate(`/status-reservation/${reservationUpdatedInfo.reservationId}`);
		}
	}, [
		navigate,
		reservationUpdatedInfo.reservationId,
		reservationUpdatedInfo.updated,
	]);

	const handleButtonText = () => {
		if (reservationId && sendingData) return 'Updating...';
		if (reservationId) return 'Update';
		if (sendingData) return 'Confirming...';
		return 'Confirm';
	};

	return (
		<>
			{!showPickerTime && !showPickerGuests ? (
				<div className="ConfirmBox">
					<button
						className={
							sendingData
								? 'ConfirmingButton'
								: disableContinueButton()
								? 'ContinueButton'
								: 'ContinueDisabled'
						}
						disabled={
							sendingData ? true : !disableContinueButton() ? true : false
						}
						style={reservationId ? { width: '48%' } : { width: '100%' }}
						onClick={() =>
							reservationId
								? handleUpdateReservation()
								: handleCreateReservation()
						}>
						{handleButtonText()}
					</button>
					<button
						className="CancelButton"
						style={reservationId ? { display: 'block' } : { display: 'none' }}
						onClick={(e) => cancelAReservation(e)}>
						Cancel
					</button>
				</div>
			) : null}
		</>
	);
}
