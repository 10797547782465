import './accessDenied.scss';

export default function AccessDenied() {
	return (
		<>
			<div className="accessMainBox">
				<div className="accessBox">
					<div className="accessDescription">
						<p className="accessTitle">
							Something went wrong. Wait a moment and try again.
						</p>
					</div>
					<div className="accessButtonBox">
						<button
							className="accessButton"
							onClick={() => window.location.reload()}>
							Retry
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
