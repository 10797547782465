import axios from '../client';

export const getVenue = async (venueId) => {
	try {
		const venue = await axios
			.get(`/api/v1/user/venue/${venueId}`)
			.then((res) => res.data.venue)
			.catch((err) => err.message);
		return venue;
	} catch (e) {
		return e;
	}
};
