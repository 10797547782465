import axios from '../client';

export const cancelReservation = async (reservationId) => {
	const adapterResponse = (data) => {
		if (!data.cancelledReservation) return { cancelled: false };
		let reservationId = data.cancelledReservation.reservationId;
		return { cancelled: true, reservationId };
	};
	try {
		const reservation = await axios
			.post(`/api/v1/user/cancel-reservation`, { reservationId })
			.then((res) => adapterResponse(res.data))
			.catch((err) => ({ cancelled: false, message: err.message }));

		return reservation;
	} catch (e) {
		return e;
	}
};
