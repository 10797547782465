import moment from 'moment';
import axios from '../client';

const adapterResponse = (data) => {
	let times = [];
	if (!data?.availability.length) return times;
	for (var i = 0; i < data.availability.length; i++) {
		for (var j = 0; j < data.availability[i].times.length; j++) {
			times.push(data.availability[i].times[j]);
		}
	}
	if (times.length > 6) {
		times = times.slice(0, 5);
	}

	return times;
};

export const getVenueAvailability = async (data) => {
	const { venueId, date, time, party_size } = data;
	const query = new URLSearchParams({
		venueId: venueId,
		party_size: party_size,
		date: date,
		start_time: moment(time, 'LT').format('HH:mm'),
	}).toString();
	try {
		const venueAvailability = await axios
			.get(`/api/v1/user/venue-availability?${query}`)
			.then((res) => adapterResponse(res.data))
			.catch((err) => err.message);
		return venueAvailability;
	} catch (e) {
		return e.message;
	}
};
