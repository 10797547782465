import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { AuthContext, AppContext } from '../../context';

export default function Home() {
	const { accessToken } = useContext(AuthContext);
	const { venueId, reservationId, reservationType, setLoading } =
		useContext(AppContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (accessToken.length === 0) return navigate(`/access-denied`);
		if (reservationType.length === 0) return;
		if (venueId.length === 0) return;
		switch (reservationType) {
			case 'Create':
				navigate(`/create-reservation/${venueId}`);
				break;
			case 'Cancel':
				if (reservationId.length === 0) {
					console.error('Missing reservation id');
					break;
				}
				navigate(`/cancel-reservation/${reservationId}`);
				break;
			case 'Update':
				if (reservationId.length === 0) {
					console.error('Missing reservation id');
					break;
				}
				navigate(`/update-reservation/${venueId}/${reservationId}`);
				break;
			case 'Status':
				if (reservationId.length === 0) {
					console.error('Missing reservation id');
					break;
				}
				navigate(`/status-reservation/${reservationId}`);
				break;
			default:
				navigate(`/access-denied`);
				break;
		}
		setLoading(true);
		// eslint-disable-next-line
	}, [accessToken, reservationId, reservationType, venueId]);
	return <></>;
}
