import { whiteColor } from "../../helpers/colorConstants";

export const RefreshPage = ({
	buttonText,
	buttonStyles = true,
	className,
	onClick,
}) => (
	<button
		style={
			buttonStyles
				? {
						borderRadius: '10px',
						padding: '5px',
						position: 'absolute',
						right: '5%',
						top: '80%',
				  }
				: {
						color: whiteColor,
						background: 'transparent',
						border: 'none',
				  }
		}
		className={className ? className : ''}
		onClick={() => onClick()}>
		{buttonText ? buttonText : 'Click to Refresh'}
	</button>
);
