import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from '../../pages/Home';
import CreateReservation from '../../pages/CreateReservation';
import UpdateReservation from '../../pages/UpdateReservation';
import StatusReservation from '../../pages/StatusReservation';
import CancelReservation from '../../pages/CancelReservation';
import AccessDenied from '../../pages/AccessDenied';

export const sitemap = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/create-reservation/:venueId',
		element: <CreateReservation />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/update-reservation/:venueId/:reservationId',
		element: <UpdateReservation />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/status-reservation/:reservationId',
		element: <StatusReservation />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/cancel-reservation/:reservationId',
		element: <CancelReservation />,
		errorElement: <AccessDenied />,
	},
	{
		path: '/access-denied',
		element: <AccessDenied />,
		errorElement: <AccessDenied />,
	},
]);

export default function routing(props) {
	return <RouterProvider router={sitemap} {...props} />;
}
