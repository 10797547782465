import React, { useState } from 'react';
import WheelPicker from 'react-simple-wheel-picker';
import styled from 'styled-components';
import './customPicker.scss';
import { getTimeValues, newOptionPickerGroups } from './helper';
import { blackColor, greyColor } from '../../helpers/colorConstants';

const optionTimeGroups = {
	hours: getTimeValues(1, 12),
	minutes: getTimeValues(0, 59),
	timePeriod: ['am', 'pm'],
};
const opGroups = newOptionPickerGroups(optionTimeGroups);

const GuestPickerContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const StyledWheelTimePicker = styled(WheelPicker)`
	box-shadow: none;
`;

export default function CustomPickerTime(props) {
	const { updatePickerTime, cancelPickerTime, timeValueGroups } = props;
	const [valueGroups, setValueGroups] = useState({
		minutes: timeValueGroups.minutes,
		hours: timeValueGroups.hours,
		timePeriod: timeValueGroups.timePeriod,
	});
	const [minutesValue, setMinutesValue] = useState('');
	const [hoursValue, setHoursValue] = useState('');
	const [timePeriodValue, setTimePeriodValue] = useState('');

	const handlePickerTimeUpdate = (e) => {
		e.preventDefault();
		updatePickerTime({
			minutes: minutesValue,
			hours: hoursValue,
			timePeriod: timePeriodValue,
		});
	};
	const handlePickerTimeCancel = (e) => {
		e.preventDefault();
		cancelPickerTime();
	};

	const handleOnChangeHours = (e) => {
		setValueGroups({
			hours: e.value,
			minutes: valueGroups.minutes,
			timePeriod: valueGroups.timePeriod,
		});
		setHoursValue(e.value);
	};
	const handleOnChangeMinutes = (e) => {
		setValueGroups({
			hours: valueGroups.hours,
			minutes: e.value,
			timePeriod: valueGroups.timePeriod,
		});
		setMinutesValue(e.value);
	};
	const handleOnChangeTimePeriod = (e) => {
		setTimePeriodValue(e.value);
		setValueGroups({
			hours: valueGroups.hours,
			minutes: valueGroups.minutes,
			timePeriod: e.value,
		});
	};

	return (
		<div className="PickerTime">
			<GuestPickerContainer>
				<StyledWheelTimePicker
					data={opGroups['hours']}
					onChange={(e) => handleOnChangeHours(e)}
					height={200}
					width={100}
					itemHeight={36}
					selectedID={
						Number(
							opGroups['hours'].findIndex(
								(hours) => hours.value === timeValueGroups.hours,
							),
						) + 1
					}
					color={greyColor}
					activeColor={greyColor}
					backgroundColor={blackColor}
					shadowColor="none"
				/>
				<StyledWheelTimePicker
					data={opGroups['minutes']}
					onChange={(e) => handleOnChangeMinutes(e)}
					height={200}
					width={100}
					itemHeight={36}
					selectedID={opGroups['minutes'].findIndex(
						(minutes) => minutes.value === timeValueGroups.minutes,
					)}
					color={greyColor}
					activeColor={greyColor}
					backgroundColor={blackColor}
					shadowColor="none"
				/>
				<StyledWheelTimePicker
					data={opGroups['timePeriod']}
					onChange={(e) => handleOnChangeTimePeriod(e)}
					height={200}
					width={100}
					itemHeight={36}
					selectedID={timeValueGroups.timePeriod}
					color={greyColor}
					activeColor={greyColor}
					backgroundColor={blackColor}
					shadowColor="none"
				/>
			</GuestPickerContainer>
			<div className="ConfirmBoxPicker">
				<button
					onClick={(e) => handlePickerTimeUpdate(e)}
					className={'ConfirmingButtonPicker'}>
					Set
				</button>
				<button
					onClick={(e) => handlePickerTimeCancel(e)}
					className={'CancelButtonPicker'}>
					Cancel
				</button>
			</div>
		</div>
	);
}
