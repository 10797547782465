export const getTimeValues = (minValue, maxValue) => {
	let array = [];
	for (let index = minValue; index <= maxValue; index++) {
		array.push(index);
	}
	return array.map(String);
};

export const setKeyValue = (arr) => {
	return arr.map((item) => {
		const dataSet = {
			id: item,
			value: item,
		};
		return dataSet;
	});
};

export const newOptionPickerGroups = (optionGroups) => {
	let groups = {};
	for (const group in optionGroups) {
		groups[group] = setKeyValue(optionGroups[group]);
	}
	return groups;
};
