import './notes.scss';

export default function Notes() {
	return (
		<div className="noteBox">
			<p className="notesTitle">Notes</p>
			<div className="notesDescription">
				<p>
					We have a 15 minute grace period. Please call us if you are running
					later than 15 minutes after your reservation time.
				</p>
				<p>
					We may contact you about this reservation, so please ensure your email
					and phone number are up to date.
				</p>
				<p>
					Your table will be reserved for 1 hour 30 minutes for parties of up to
					2; 2 hours for parties of up to 6; and 2 hours 30 minutes for parties
					of 7+.
				</p>
			</div>
		</div>
	);
}
