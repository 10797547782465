import moment from 'moment';
import React from 'react';
import { useFormStore } from '../../../store';

export default function AvailableTimes() {
	const { availability, availableTimeSelected, setAvailableTimeSelected } =
		useFormStore((state) => state);

	const onChangeTime = (e) => {
		e.preventDefault();
		setAvailableTimeSelected(e.target.value);
	};

	return (
		<div className="TimeContainer">
			<label htmlFor="timeBox">Available Times</label>
			<div className="AvailableTimeBox">
				<div className="TimeBox" id="timeBox">
					{Array.isArray(availability) && availability.length > 0 ? (
						availability?.map((t) => {
							return (
								<button
									key={t.time}
									type=""
									value={t.time}
									className={
										t.time === availableTimeSelected ||
										moment(t.time, 'LT').format('hh:mm a') ===
											availableTimeSelected
											? 'TimeButtonSelected'
											: 'TimeButton'
									}
									onClick={(e) => onChangeTime(e, t.seatingAreas)}>
									{moment(t.time, 'LT').format('hh:mma')}
								</button>
							);
						})
					) : (
						<p className="unavailable">0 available times</p>
					)}
				</div>
			</div>
		</div>
	);
}
