import React from 'react';
import cn from 'classnames';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import CustomPickerTime from '../../customPickerSelect/customPickerTime';
import { useFormStore } from '../../../store';

export default function Time() {
	const {
		toggleShowPickerGuests,
		toggleShowPickerTime,
		time,
		timeValueGroups,
		setTime,
		showPickerTime,
		resetSomeStates,
	} = useFormStore((state) => state);

	const updatePickerGuests = (time) => {
		setTime(time, time);
		toggleShowPickerTime(false);
		resetSomeStates();
	};

	const partyTimeSelect = () => {
		toggleShowPickerGuests(false);
		toggleShowPickerTime(!showPickerTime);
	};

	return (
		<>
			<div className="DateTime__DateTimeInputBox">
				<label htmlFor="time">Time</label>
				<div id="partyTimeSelect" onClick={partyTimeSelect}>
					<span
						className={cn([
							'partySelectData',
							{ default: time === 'Select Time' },
						])}>
						{time && time.split(' ').join('')}
					</span>
					<span>
						{!showPickerTime ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
					</span>
				</div>
			</div>
			{showPickerTime && (
				<CustomPickerTime
					updatePickerTime={updatePickerGuests}
					cancelPickerTime={partyTimeSelect}
					timeValueGroups={timeValueGroups}
				/>
			)}
		</>
	);
}
