import React from 'react';
import './loader.scss';

export default function Loader() {
	return (
		<main className="loader" role="alert" aria-busy="true">
			<div className="loader" aria-label="Loading">
				<div className="loadingio-spinner-rolling-3nwocq145a2">
					<div className="ldio-kiv8subw9ue">
						<div></div>
					</div>
				</div>
			</div>
		</main>
	);
}
