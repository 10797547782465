import React, { useEffect, useState } from 'react';
import { getVenue } from '../../services';
import { useParams } from 'react-router-dom';
import './venue.scss';

export default function Venue() {
	const { venueId } = useParams();
	const [venue, setVenue] = useState(null);

	useEffect(() => {
		if (!venue) {
			const fetchData = async () => {
				let newState = await getVenue(venueId);
				setVenue(newState);
			};
			fetchData();
		}
	}, [venueId, venue]);

	return (
		<>
			<div className="venueBox">
				{venue && (
					<div className="venueInformation">
						<p className="venueTitle">{venue.venue?.toUpperCase()}</p>
						<p className="venueDescription">{venue?.address}</p>
						<div className='venueSecondarySection'>
							<p className='venueDescription'>
								{venue.city && `${venue?.city}, ${venue?.zipCode}`}
							</p>
							<a className="venueExplore" href="/explore">Explore</a>
						</div>
					</div>
				)}
			</div>
		</>
	);
}
