import axios from '../client';

export const updateReservation = async (data) => {
	const adapterResponse = (data) => {
		if (!data.newReservation) return { updated: false };
		let reservationId = data.newReservation.reservation_id;
		return { updated: true, reservationId };
	};
	try {
		const reservation = await axios
			.post(`/api/v1/user/update-reservation`, data)
			.then((res) => adapterResponse(res.data))
			.catch((err) => ({ created: false, message: err.message }));

		return reservation;
	} catch (e) {
		return e;
	}
};
