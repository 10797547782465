import { useParams, useNavigate } from 'react-router-dom';
import { cancelReservation } from '../../services/sevenrooms/cancelReservation';
import cancelBackground from '../../assets/images/cancel-background.png';
import './cancelReservation.scss';

export default function CancelReservation() {
	const { reservationId } = useParams();
	const navigate = useNavigate();
	const cancelAReservation = async () => {
		let reservation = await cancelReservation(reservationId);
		if (reservation.cancelled) {
			navigate(`/status-reservation/${reservation.reservationId}`);
		}
	};

	return (
		<>
			<div
				className="cancelMainBox"
				style={{
					backgroundImage: `url(${cancelBackground})`,
				}}>
				<div className="cancelContainer">
					<div className="cancelBox">
						<div className="cancelDescription">
							<p className="cancelTitle">
								Are you sure you want to cancel your reservation?
							</p>
						</div>
						<div className="cancelButtonsBox">
							<button
								className="cancelButton"
								onClick={() => cancelAReservation()}>
								Cancel Reservation
							</button>
							<button
								className="cancelBackButton"
								onClick={() => window.location.reload()}>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
