import { confirmed, canceled, cancelled } from '../../helpers/constants';
import moment from 'moment/moment.js';
import './reservation.scss';

// Icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { whiteColor,primaryColor } from '../../helpers/colorConstants';


export default function Reservation({
	status,
	venue,
	city,
	date,
	time,
	guests
}) {
	if (!status || !venue || !city || !date || !time || !guests ) return;

	if (Number.isNaN(guests)) return;

	const sliceCity = (city) => {
		let newCity = '';
		if (!city.length) return newCity;
		let arrCity = city.split(' ');
		for (var i = 0; i < arrCity.length; i++) {
			newCity = newCity.concat(arrCity[i][0]);
			newCity = newCity.concat(arrCity[i][1]);
		}
		return newCity;
	};

	const dateReservation = moment(date).format('dddd, MMMM D');
	const timeReservation = moment(time, 'HH:mm a').format('h:mm a');
	const cityReservation = sliceCity(city);

	return (
		<>
			<div className="reservationBox">
				{status === confirmed && (
					<CheckCircleOutlineIcon
						sx={{ width: '48px', height: '48px', color: whiteColor }}
					/>
				)}
				{status === canceled && (
					<CancelOutlinedIcon
						sx={{ width: '48px', height: '48px', color: primaryColor }}
					/>
				)}
				{status === cancelled && (
					<CancelOutlinedIcon
						sx={{ width: '48px', height: '48px', color: primaryColor }}
					/>
				)}
				<div className="reservationDescription">
					<p className="reservationTitle">
						{status === cancelled && 'Reservation '}
						{status === canceled && 'Reservation '}
						{status === confirmed && 'Table '} 
						{status}
					</p>
					<p className="reservationTitle">{`${venue}, ${cityReservation}`}</p>
					<p className="reservationCorp">
						{dateReservation} <br />
						{`${timeReservation}, ${guests} ${guests > 1 ? 'guests' : 'guest'}`}
					</p>
				</div>
				<div className="doneButtonBox">
					<form>
						<button
							className={
								status === canceled ? 'doneCanceledButton' : 'doneButton'
							}
							type="submit">
							Done
						</button>
					</form>
				</div>
			</div>
		</>
	);
}
