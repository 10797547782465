import React from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import cn from 'classnames';
import CustomPickerGuests from '../../customPickerSelect/customPickerGuests';
import { useFormStore } from '../../../store';

export default function PartySize() {
	const resetSomeStates = useFormStore((state) => state.resetSomeStates);
	const toggleShowPickerGuests = useFormStore(
		(state) => state.toggleShowPickerGuests,
	);
	const partySize = useFormStore((state) => state.partySize);
	const setPartySize = useFormStore((state) => state.setPartySize);
	const showPickerGuests = useFormStore((state) => state.showPickerGuests);

	const partySizeSelect = () => {
		toggleShowPickerGuests(!showPickerGuests);
	};

	const updatePickerGuests = (guests) => {
		setPartySize(guests);
		toggleShowPickerGuests(false);
		resetSomeStates();
	};

	return (
		<>
			<div className="DateTime__PartySizeInputBox">
				<label htmlFor="guests">Party Size</label>
				<div id="partySizeSelect" onClick={partySizeSelect}>
					<span
						className={cn([
							'partySelectData',
							{ default: partySize === 'Select Size' },
						])}>
						{partySize} {partySize > 1 ? 'Guests' : 'Guest'}
					</span>
					<span>
						{!showPickerGuests ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
					</span>
				</div>
			</div>
			{showPickerGuests && (
				<CustomPickerGuests
					updatePickerGuests={updatePickerGuests}
					cancelPickerGuests={partySizeSelect}
					guessValueGroups={partySize}
				/>
			)}
		</>
	);
}
