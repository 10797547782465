import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useRef } from 'react';
import moment from 'moment';
// Components
import Venue from '../../components/venue';
import Notes from '../../components/notes';
import Form from '../../components/form';
import Buttons from '../../components/form/buttons';
import { RefreshPage } from '../../components/refreshPage';
import { useFormStore } from '../../store';
import './updateReservation.scss';
import Loader from '../../components/loader';

export default function UpdateReservation() {
	const { reservationId } = useParams();
	const { venueId } = useParams();
	const fetchReservation = useFormStore((state) => state.fetchReservation);
	const fetchVenueAvailability = useFormStore(
		(state) => state.fetchVenueAvailability,
	);
	const setVenueId = useFormStore((state) => state.setVenueId);
	const setReservationId = useFormStore((state) => state.setReservationId);
	const availableTimeSelected = useFormStore(
		(state) => state.availableTimeSelected,
	);
	const date = useFormStore((state) => state.date);
	const loading = useFormStore((state) => state.loading);
	const time = useFormStore((state) => state.time);
	const partySize = useFormStore((state) => state.partySize);
	const refContainer = useRef();
	const refAvailabilities = useRef();

	setVenueId(venueId);
	setReservationId(reservationId);

	const handleGetAvailability = useCallback(
		(forceGet) => {
			fetchVenueAvailability(forceGet);
		},
		[fetchVenueAvailability],
	);

	const handleFetchReservation = useCallback(() => {
		fetchReservation();
	}, [fetchReservation]);

	useEffect(() => {
		if (availableTimeSelected && !refAvailabilities.current) {
			handleGetAvailability(true);
			refAvailabilities.current = true;
		}
	}, [availableTimeSelected, handleGetAvailability]);

	useEffect(() => {
		handleGetAvailability();
	}, [date, time, partySize, handleGetAvailability]);

	useEffect(() => {
		if (!refContainer.current) {
			handleFetchReservation();
		}
	}, []);

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<div className="reserveContainer">
					<div className="reserveHead">
						<RefreshPage
							className="reserveClose"
							buttonText="Close"
							buttonStyles={false}
							onClick={() => window.location.reload()}
						/>
						<p className="reserveTitle">Reserve</p>
						<p className="reserveClose"></p>
					</div>
					<Venue />
					<Form />
					<Notes />
					<Buttons />
				</div>
			)}
		</>
	);
}
