import moment from 'moment';

export const formatTime = (timeValue) => {
	if (!timeValue.hours) return moment(timeValue, 'LT').format('hh:mm a');

	let timeString = `${formatTimeValue(timeValue.hours)}:${formatTimeValue(
		timeValue.minutes,
	)} ${formatTimeValue(timeValue.timePeriod)}`;
	return timeString;
};

const formatTimeValue = (timeValue) => {
	let returnValue = timeValue.length === 1 ? `0${timeValue}` : timeValue;
	return returnValue;
};
