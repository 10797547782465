import moment from 'moment';
import { subscribeWithSelector } from 'zustand/middleware';
import { create } from 'zustand';
import {
	createReservation,
	getReservation,
	getVenueAvailability,
	updateReservation,
} from '../services';
import { formatTime } from './helper';

export const useFormStore = create(
	subscribeWithSelector((set, get) => ({

		showPickerGuests: false,
		toggleShowPickerGuests: (showPickerGuests) =>
			set(() => ({ showPickerGuests })),

		showPickerTime: false,
		toggleShowPickerTime: (showPickerTime) => set(() => ({ showPickerTime })),

		partySize: '2',
		setPartySize: (partySize) => set({ partySize }),

		date: moment().format('YYYY-MM-DD'),
		setDate: (date) => set({ date }),

		time: moment(new Date(), 'LT').format('hh:mm a'),
		timeValueGroups: {
			minutes: '0',
			hours: '1',
			timePeriod: 'pm',
		},
		setTime: (time, timeValueGroups) =>
			set({
				time: formatTime(time),
				timeValueGroups: timeValueGroups
					? timeValueGroups
					: get().timeValueGroups,
			}),

		availability: [],
		fetchVenueAvailability: async (forceGet = false) => {
			if (get().sendData || forceGet) {
				set(() => ({ loading: true }));
				const response = await getVenueAvailability({
					venueId: get().venueId,
					date: get().date,
					time: get().time,
					party_size: get().partySize,
				});
				set(() => ({
					loading: false,
					sendData: false,
				}));
				if (typeof response === 'string') return set({ availability: [] });

				const time = moment(get().time, 'LT');
				const rest = time.format('mm') % 15;
				let formatTime;
				if (rest < 8) {
					formatTime = time.subtract(rest, 'minutes').format('hh:mm a');
				} else {
					formatTime = time.add(15 - rest, 'minutes').format('hh:mm a');
				}
				set({ availability: [], time: formatTime });
				if (response.length > 0) {
					set({
						time: moment(response[0].time, 'LT').format('hh:mm a'),
						availability: response.map((res) => ({
							time: res.time,
							seatingAreas: res.areas,
						})),
					});
				}
				set({ sendData: true });
			} else {
				set({ sendData: true });
			}
		},

		sendData: false,

		sendingData: false,
		setSendingData: (sendingData) => set(() => ({ sendingData })),

		availableTimeSelected: null,
		setAvailableTimeSelected: (availableTimeSelected) =>
			set(() => ({ availableTimeSelected })),

		reservationCreatedInfo: [],
		fetchCreateReservation: async () => {
			set(() => ({ sendingData: true }));
			const data = {
				venueId: get().venueId,
				party_size: get().partySize,
				date: get().date,
				time: moment(get().availableTimeSelected, 'LT').format('HH:mm'),
			};
			let reservation = await createReservation(data);
			set(() => ({ reservationCreatedInfo: reservation }));
			set(() => ({ sendingData: false }));
		},

		venueId: '',
		setVenueId: (venueId) => set(() => ({ venueId })),

		reservationId: '',
		setReservationId: (reservationId) => set(() => ({ reservationId })),

		loading: false,

		fetchReservation: async () => {
			set(() => ({ loading: true }));
			let reservation = await getReservation(get().reservationId);
			set(() => ({
				partySize: reservation.guests,
				date: reservation.date,
				time: moment(reservation.time, 'LT').format('hh:mm a'),
				availableTimeSelected: moment(reservation.time, 'LT').format('hh:mm a'),
			}));
			set(() => ({ loading: false }));
		},

		reservationUpdatedInfo: [],
		fetchUpdateReservation: async () => {
			set(() => ({ sendingData: true }));
			const data = {
				venueId: get().venueId,
				party_size: get().partySize,
				date: get().date,
				time: moment(get().availableTimeSelected, 'LT').format('HH:mm'),
				reservationId: get().reservationId,
			};
			let reservation = await updateReservation(data);
			set(() => ({ reservationUpdatedInfo: reservation }));
			set(() => ({ sendingData: false }));
		},

		resetSomeStates: () =>
			set(() => ({
				availableTimeSelected: null,
			})),
	})),
);
