import React, { useState } from 'react';
import WheelPicker from 'react-simple-wheel-picker';
import styled from 'styled-components';
import './customPicker.scss';
import { getTimeValues, newOptionPickerGroups } from './helper';
import { blackColor , greyColor } from '../../helpers/colorConstants';

const optionGuestGroups = {
	guests: getTimeValues(1, 12),
	title: ['Guests'],
};

const opGroups = newOptionPickerGroups(optionGuestGroups);

const GuestPickerContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const StyledWheelGuestPicker = styled(WheelPicker)`
	box-shadow: none;
`;

export default function CustomPickerGuests(props) {
	const { updatePickerGuests, cancelPickerGuests, guessValueGroups } = props;
	const [valueGroups, setValueGroups] = useState({
		guests: isNaN(guessValueGroups)
			? '2'
			: (Number(guessValueGroups)).toString(),
		title: 'guests',
	});
	const handlePickerGuestUpdate = (e) => {
		e.preventDefault();
		updatePickerGuests(valueGroups.guests);
	};

	const handlePickerGuestCancel = (e) => {
		e.preventDefault();
		cancelPickerGuests();
	};

	const handleOnChange = (data) => {
		setValueGroups({
			guests: data.value,
			title: 'guests',
		});
	};

	return (
		<div className="PickerGuests">
			<GuestPickerContainer>
				<StyledWheelGuestPicker
					data={opGroups['guests']}
					onChange={(e) => handleOnChange(e)}
					height={200}
					width={100}
					itemHeight={36}
					selectedID={Number(opGroups['guests'].findIndex(
						(guests) => guests.value === valueGroups.guests,
					))+1}
					color={greyColor}
					activeColor={greyColor}
					backgroundColor={blackColor}
					shadowColor="none"
				/>
				<StyledWheelGuestPicker
					data={opGroups['title']}
					onChange={(e) => handleOnChange(e)}
					height={200}
					width={100}
					itemHeight={36}
					selectedID={opGroups['title'][0].id}
					color={greyColor}
					activeColor={greyColor}
					backgroundColor={blackColor}
					shadowColor="none"
				/>
			</GuestPickerContainer>
			<div className="ConfirmBoxPicker">
				<button
					onClick={(e) => handlePickerGuestUpdate(e)}
					className={'ConfirmingButtonPicker'}>
					Set
				</button>
				<button
					onClick={(e) => handlePickerGuestCancel(e)}
					className={'CancelButtonPicker'}>
					Cancel
				</button>
			</div>
		</div>
	);
}
