import React from 'react';
import Date from '../../inputs/Date';
import PartySize from '../../inputs/PartySize';
import Time from '../../inputs/Time';

export default function Inputs() {
	return (
		<div className="InputBox">
			<PartySize />
			<div className="DateTime__Container">
				<Date />
				<Time />
			</div>
		</div>
	);
}
